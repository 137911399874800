// -----------------------------
// CB Illustrations
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/illustrations.scss";


.cb-ilus {
  // border: 1px solid $gray4;
  display: inline-block;
  width: 64px;
  height: 64px;
  position: relative;
  &::after {
    position: absolute;
    display: block;
    content: "";
    background-repeat: no-repeat;
  }

  @include ilus-size();
}

// ------------------------------------ CUSTOM PLACEMENT
$ilusCustom: ("dollar", "mail-send", "graduate", "raised-pencil");
@each $type in $ilusCustom {
  .cb-ilus-#{$type} {
    @include ilus-size(16px, 16px);
  }
}
// ------------------------------------ SIZE PATTERN
$ilusSize: (64, 72, 96);
@each $size in $ilusSize {
  .cb-ilus {
    &.cb-ilus-#{$size} {
      width: #{$size}px;
      height: #{$size}px;
    }
  }
}

// Per breakpoint
@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-only($breakpoint, $grid-breakpoints) {
    @each $size in $ilusSize {
      .cb-ilus-#{$breakpoint}-#{$size} {
        width: #{$size}px !important;
        height: #{$size}px !important;
      }
    }
  }

  // UP
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $ilusSize {
      .cb-ilus-#{$breakpoint}-up-#{$size} {
        width: #{$size}px !important;
        height: #{$size}px !important;
      }
    }
  }

  // Down
  @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
    @each $size in $ilusSize {
      .cb-ilus-#{$breakpoint}-down-#{$size} {
        width: #{$size}px !important;
        height: #{$size}px !important;
      }
    }
  }
}
// ------------------------------------ SHADOW
.cb-ilus-shadow {
  &::before {
    content: "";
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJzaGFkb3ciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIxLjExIDMuMjUiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojY2NkOGYyO308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMS4xMSwxLjYyYzAtLjktNC43My0xLjYyLTEwLjU1LTEuNjJTMCwuNzMsMCwxLjYyczQuNzMsMS42MiwxMC41NSwxLjYyLDEwLjU1LS43MywxMC41NS0xLjYyWiIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 50% calc(100% - 8px);
    display: block;
    width: 100%;
    height: 100%;
  }
  &.cb-ilus {
    &::after {
      top: calc(50% - (calc(100% - 16px) / 2));
    }
  }
}
// ------------------------------------ CIRCULAR

.cb-ilus-circular {
  &::after {
    top: 0 !important;
  }
  &::before {
    background-position: 50% calc(100% - 16px);
  }
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2NiA2NiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjYgNjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRjVGN0ZDO30KCS5zdDF7ZmlsbDojRTZFREY4O30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMSwzM2MwLDguNSwzLjQsMTYuNiw5LjQsMjIuNmM2LDYsMTQuMSw5LjQsMjIuNiw5LjRzMTYuNi0zLjQsMjIuNi05LjRjNi02LDkuNC0xNC4xLDkuNC0yMi42CgkJcy0zLjQtMTYuNi05LjQtMjIuNkM0OS42LDQuNCw0MS41LDEsMzMsMXMtMTYuNiwzLjQtMjIuNiw5LjRDNC40LDE2LjQsMSwyNC41LDEsMzN6Ii8+Cgk8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMzMsNjUuNGMtOC43LDAtMTYuOC0zLjQtMjIuOS05LjVDNCw0OS44LDAuNiw0MS43LDAuNiwzM2MwLTguNywzLjQtMTYuOCw5LjUtMjIuOUMxNi4yLDQsMjQuMywwLjYsMzMsMC42CgkJYzguNywwLDE2LjgsMy40LDIyLjksOS41YzYuMSw2LjEsOS41LDE0LjMsOS41LDIyLjljMCw4LjctMy40LDE2LjgtOS41LDIyLjlDNDkuOCw2Miw0MS43LDY1LjQsMzMsNjUuNHogTTMzLDEuNAoJCWMtOC40LDAtMTYuNCwzLjMtMjIuMyw5LjJjLTYsNi05LjIsMTMuOS05LjIsMjIuM2MwLDguNCwzLjMsMTYuNCw5LjIsMjIuM3MxMy45LDkuMiwyMi4zLDkuMmM4LjQsMCwxNi40LTMuMywyMi4zLTkuMgoJCWM2LTYsOS4yLTEzLjksOS4yLTIyLjNjMC04LjQtMy4zLTE2LjQtOS4yLTIyLjNTNDEuNCwxLjQsMzMsMS40eiIvPgo8L2c+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: calc(50% - (calc(100% - 16px) / 2)) calc(50% - (calc(100% - 16px) / 2));
  background-size: calc(100% - 16px) calc(100% - 16px);
}
// ------------------------------------ CUSTOM CIRCULAR
.cb-ilus-custom-circular {
  border-radius: 50%;
  &.cb-ilus {
    // $ilusSize: (64, 72, 96);
    &.cb-ilus-96 {
      @include ilus-size(40px, 32px);
    }
    &.cb-ilus-72 {
      @include ilus-size(32px, 24px);
    }
    &.cb-ilus-64 {
      @include ilus-size(28px, 20px);
    }
  }
}

// ------------------------------------ MONOTONE and DUOTONE
@import "illustrations-monotone.scss";
@import "illustrations-duotone.scss";
@import "illustrations-higher-ed.scss";
@import "illustrations-k-12.scss";
